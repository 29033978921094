<template>
  <div class="topbar">
    <div>
    <div class="logo">
				<img :src="require('../assets/new logo.png')">
     </div>
     
    </div>
    <div class="top">
      <!-- <span class="ml-3"><img :src="require('../assets/ceza icons/alert.png')"/></span> -->
      <button type="button" class="btn btn-primary position-relative"><router-link  to="/documents"><img :src="require('../assets/ceza icons/notification.png')"/>
        <span v-if="pendingOnly.length > 0" class="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
        </span></router-link>
        
        
    </button>
      <b-dropdown class="dropdown-btn " variant="outlined-light">
       
				<div slot="button-content" class="btn btn-main" >
					<span class="p text-dark">{{userId}} </span>
				</div>
        <b-dropdown-item  @click="changePassword">Change Password</b-dropdown-item>
			
				<b-dropdown-item  @click="logout">Logout</b-dropdown-item>
			</b-dropdown>
      <div>
      
      </div>
      
    </div>
    <ChangePassword/>
    <Logout/>
  </div>
</template>

<style lang="scss" scoped>
.options{
    width: 100%;
    display: flex;
}
.options button{
    width: auto;
    padding: 10px;
    margin: 5px;
    border: none !important;
    
    border-radius:5px !important;
    border: 1px transparent solid !important;
    
}
.options button:hover{
    background-image: linear-gradient(#007bff, #ad09cf) !important;
}
.options button.active{
    background-color:#007bff !important;
    border: 1px #007bff solid !important;
   
}
.logo img{
  width: 9rem;
  padding-top: 10px !important;

}

  .topbar {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: auto;
    padding: 10px 20px;
    background-image: url('~@/assets/header-ceza.png');
    border-bottom: 1px solid #000;
    z-index: 9999;
    .icon {
      padding: 10px 16px;
      cursor: pointer;
    }
    .icon-circle {
      width: 32px;
      height: 32px;
      padding: 5px;
      margin-left: 10px;
      cursor: pointer;
      color: #222;
      background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
      border-radius: 50%;
    }
   
    .btn-main{
        background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
        background-size:0% 100%;
        background-repeat:no-repeat;
        transition:.4s;
        width:100%;
        border-radius: 10px;
        border: 1px #007bff solid;
        color:white !important;
      }
      .btn-main:hover{
        background-size:100% 100%;
          color:white !important;
      }
    .small-img{
        cursor: pointer;
        width: 3rem;
    }
    .top{
      align-content: center;
      align-items: center;
      margin-right: 20px;
    }
    .dropdown-btn {
      margin-right: 20px;
    }
  }

  @media (min-width: 250px) and (max-width: 500px) {
       /* styles for mobile browsers smaller than 480px; (iPhone) */

     .small-img{
        display: none;
    }
    .top{
      display: block !important;
    }
   .topbar{
     width: 100vw;
    height: 120px;
   }
    .p{
      font-size: 10px;
    }
    

      

      
    }
     @media (min-width: 500px) and (max-width: 580px) {
		  .small-img{
       display: none;
    }
      .top{
        display: block !important;
      }
    
      .p{
        font-size: 15px;
      }



     }

    @media (min-width: 600px) and (max-width: 1024px) {
       .small-img{
        width: 2rem !important;
        }
        .top{
          display: block !important;
        }
      
        .p{
          font-size: 20px;
        }
		
       
       
    }
    @media only screen and (min-width: 1280) {
      
    
    }
</style>
<script>
import Logout from '../components/modal/Logout.vue';
import ChangePassword from '../components/modal/ChangePassword.vue'

export default {
  name: 'TheTopbar',
	components: {
        Logout,
        ChangePassword
        
	},
    data: function(){
        return{
			  state:'',
        userId:'',
        files: [],
        
        }
    },
    computed: { 
		init() {
			return this.$store.state.init;
	    },
	    errors() {
				return this.$store.state.errors;
	    },
	    show() {
				return this.$store.state.show;
	    },
      pendingOnly(){
          return this.files.filter(item => item.status === 'SUBMITTED')
      },
   	},

     methods: {
      getAllFiles(){
                      const options = {
                          method: 'GET',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                         
                          url: '/ax/customer/businessfile/all'
                          };
                      this.axios(options)
                          .then((response) => { 
                            console.log(response.data)
                          this.files = response.data

                      
                          }).catch((err) => { 
                      })
          },
         getUserId(){
           this.userId = localStorage.getItem("userId")
			    let userId = localStorage.getItem("userId")
			 
          	const params = {
            userId: userId,

          }
          const data = Object.keys(params)
                  .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                  .join('&');

          const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/getPermissionsByUser',
					data
                    };

				this.axios(options)
				.then((response) => { 
          console.log(response.data)
					
           })
        },

   
      
        changePassword: function(){
          this.$bvModal.show('changePassword-modal'); 
        },
          logout: function() {
          this.$bvModal.show('logout-modal'); 
        
          },
        
      
          },
         beforeMount(){  
           this.getUserId();
            
        },
       mounted(){
        this.getAllFiles();
        
      }
  
}
</script>